import { gql } from "@apollo/client";

const GET_UPCOMING_TRANSACTIONS = gql`
  query GetUpcomingTransactions($daysAhead: Int!) {
    upcomingTransactions(daysAhead: $daysAhead) {
      id
      description
      lastAmount
      predictedNextDate
      category {
        id
        name
        icon
      }
      account {
        id
        name
        mask
      }
    }
  }
`;

export { GET_UPCOMING_TRANSACTIONS };

import React, { useEffect, useState } from "react";
import {
  GET_SAFE_TO_SPEND_BREAKDOWN,
  GET_USER_ACCOUNTS,
} from "../queries/safeToSpendQueries";
import { UPDATE_SAFE_TO_SPEND_ACCOUNTS } from "../mutations/safeToSpendMutations";
import { useMutation, useQuery } from "@apollo/client";
import { Spinner } from "./ui/Spinner";
import { getEnvelopeTypeText } from "../lib/utils";
import { Button } from "./ui/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/Dropdown";
import PropTypes from "prop-types";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Separator } from "./ui/Separator";
import { Tooltip } from "react-tooltip";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { ArrowLeftRightIcon } from "lucide-react";
import MoveFundsModal from "./MoveFundsModal";

export const useSafeToSpend = () => {
  const {
    data: breakdownData,
    loading: breakdownLoading,
    error: breakdownError,
    refetch: refetchBreakdown,
  } = useQuery(GET_SAFE_TO_SPEND_BREAKDOWN);

  return {
    breakdownData,
    breakdownLoading,
    breakdownError,
    refetchBreakdown,
  };
};

const SafeToSpend = ({
  accounts,
  lastSyncedAt,
  onSync,
  syncLoading,
  slices,
  onMoveFundsSuccess,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [localAvailableBalance, setLocalAvailableBalance] = useState(0);
  const [isMoveFundsModalOpen, setIsMoveFundsModalOpen] = useState(false);

  const { breakdownData, breakdownLoading, breakdownError, refetchBreakdown } =
    useSafeToSpend();

  const [updateSafeToSpendAccounts] = useMutation(
    UPDATE_SAFE_TO_SPEND_ACCOUNTS,
    {
      refetchQueries: [
        { query: GET_SAFE_TO_SPEND_BREAKDOWN },
        { query: GET_USER_ACCOUNTS },
      ],
    }
  );

  useEffect(() => {
    if (accounts) {
      const includedAccounts = accounts.filter(
        (account) => account.includedInSafeToSpend
      );
      setSelectedAccounts(includedAccounts.map((account) => account.id));
      setLocalAvailableBalance(
        includedAccounts.reduce(
          (sum, account) => sum + account.availableBalance,
          0
        )
      );
    }
  }, [accounts]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount / 100);
  };

  if (breakdownLoading) return <Spinner />;
  if (breakdownError)
    return (
      <p>Error loading safe to spend breakdown: {breakdownError.message}</p>
    );

  const { safeToSpend = 0, envelopeBalances = {} } =
    breakdownData?.safeToSpendBreakdownForUser || {};

  const handleAccountSelection = (accountId) => {
    const account = accounts.find((acc) => acc.id === accountId);
    const newIncluded = !selectedAccounts.includes(accountId);
    const newSelectedAccounts = newIncluded
      ? [...selectedAccounts, accountId]
      : selectedAccounts.filter((id) => id !== accountId);

    setSelectedAccounts(newSelectedAccounts);
    setLocalAvailableBalance((prevBalance) =>
      newIncluded
        ? prevBalance + account.availableBalance
        : prevBalance - account.availableBalance
    );

    updateSafeToSpendAccounts({
      variables: {
        accountIds: [accountId],
        included: newIncluded,
      },
    }).then(() => {
      refetchBreakdown();
    });
  };

  return (
    <div className="flex flex-col w-full">
      <Tooltip id="safe-to-spend-tooltip" />
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-y-4">
        <h2 className="text-xl font-semibold">Overview</h2>
        <div className="flex flex-col sm:flex-row items-stretch sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
          {accounts && accounts.length > 0 ? (
            <>
              <Button
                variant="outline"
                onClick={() => setIsMoveFundsModalOpen(true)}
                className="w-full sm:w-auto"
              >
                <ArrowLeftRightIcon className="w-4 h-4 mr-2" />
                Move Funds
              </Button>
              <Button
                onClick={onSync}
                disabled={syncLoading}
                variant="outline"
                className="w-full sm:w-auto"
              >
                Sync
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger className="w-full sm:w-auto">
                  <Button
                    variant="outline"
                    size="sm"
                    className="h-8 w-full sm:w-auto"
                  >
                    Select Accounts
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-full sm:w-auto">
                  <DropdownMenuLabel>Safe To Spend Accounts</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  {accounts.map((account) => (
                    <DropdownMenuCheckboxItem
                      key={account.id}
                      className="capitalize"
                      checked={selectedAccounts.includes(account.id)}
                      onCheckedChange={() => handleAccountSelection(account.id)}
                    >
                      {account.name}
                    </DropdownMenuCheckboxItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          ) : (
            <div className="text-sm text-gray-500">
              No accounts available. Please add accounts in settings.
            </div>
          )}
        </div>
      </div>
      <Separator orientation="horizontal" />
      <Card className="mt-4">
        <CardContent className="p-4">
          <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
            <div className="flex flex-col sm:flex-row gap-4 w-full h-full items-center justify-center">
              <div className="flex flex-col w-full sm:w-1/2 items-center justify-center gap-y-2">
                <div className="flex flex-row items-center justify-center">
                  <span className="text-sm text-gray-500 font-semibold w-full text-center">
                    Safe To Spend
                  </span>
                  <InformationCircleIcon
                    className="h-4 w-4 ml-1"
                    data-tooltip-id="safe-to-spend-tooltip"
                    data-tooltip-place="top"
                    data-tooltip-content="Amount changes based on accounts selected and slice balances."
                  />
                </div>
                <span className="text-3xl sm:text-4xl font-semibold text-center">
                  {formatCurrency(safeToSpend)}
                </span>
                <div className="text-center items-center text-xs sm:text-sm text-gray-500 justify-center">
                  <span>Last synced: </span>
                  <span className="font-semibold">{lastSyncedAt}</span>
                </div>
              </div>
              <div className="flex flex-col w-full sm:w-1/2 items-center justify-center mt-4 sm:mt-0">
                <span className="text-sm text-gray-500 font-semibold text-center">
                  Available Balance
                </span>
                <span className="text-xl sm:text-2xl font-semibold text-center">
                  {formatCurrency(localAvailableBalance)}
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-x-4 w-full">
              {Object.entries(envelopeBalances).map(([type, balance]) => (
                <div
                  key={type}
                  className="flex flex-col mt-2 w-1/2 items-center justify-center font-semibold"
                >
                  <span className="text-xs sm:text-sm text-gray-500 text-center">
                    {getEnvelopeTypeText(type)}
                  </span>
                  <span className="font-semibold text-sm sm:text-base">
                    {formatCurrency(balance)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
      <MoveFundsModal
        isOpen={isMoveFundsModalOpen}
        onClose={() => setIsMoveFundsModalOpen(false)}
        slices={slices}
        accounts={accounts}
        onSuccess={onMoveFundsSuccess}
      />
    </div>
  );
};

SafeToSpend.propTypes = {
  accounts: PropTypes.array,
  lastSyncedAt: PropTypes.string,
  onSync: PropTypes.func.isRequired,
  syncLoading: PropTypes.bool.isRequired,
  slices: PropTypes.array,
  onMoveFundsSuccess: PropTypes.func,
};

export default SafeToSpend;
